<script>
/* eslint-disable no-undef */
import { ref, onMounted } from "vue";
import { Loader } from "@googlemaps/js-api-loader";

const GOOGLE_MAPS_API_KEY = "AIzaSyAX2fNszPe6jtseVK1bk6GS1vjDFkLGJBo";

export default {
  name: "App",

  props: {
    areas: Number,
  },

  setup(props, { emit }) {
    function codeAddress() {
      var address = gAddress.value.value;
      geocoder.geocode({ address: address }, function (results, status) {
        if (status == "OK") {
          map.value.setCenter(results[0].geometry.location);
          map.value.setZoom(9);
        } else {
          alert("Não foi possível localizar o endereço inserido: " + status);
        }
      });
    }

    function selectArea(area) {
      Object.keys(areaObj.value).forEach((key) => {
        areaObj.value[key]["isSelected"] = false;
      });

      selectedArea.value = area;
      areaObj.value[area]["isSelected"] = true;
      drawingManager.value.setDrawingMode("polygon");
    }

    function deleteArea(area) {
      areaObj.value[area]["gobject"].setMap(null);
      areaObj.value[area]["gobject"] = null;
      areaObj.value[area]["geometry"] = null;
    }

    function submitModal(area) {
      if (areaName.value != null) {
        areaObj.value[area]["name"] = areaName.value;
        display.value = false;
        getGeometries();
      } else {
        alert("Inclua o nome da área");
      }
    }

    function getGeometries() {
      let geometries = {};
      Object.keys(areaObj.value).forEach((key) => {
        geometries[key] = {
          geometry: areaObj.value[key]["geometry"],
          name: areaObj.value[key]["name"],
        };
      });
      emit("geometries", geometries);
    }

    /* function insertLabel(shape, map, area){
    let content = "<p>" + area + "</p>"

    var bounds = new google.maps.LatLngBounds();
    for (var i=0; i < shape.getLength(); i++) {
          var xy = shape.getAt(i);
          bounds.extend(xy);
    }

    console.log(bounds.getCenter());




  }*/

    const loader = new Loader({
      libraries: ["drawing", "geocoder", "places", "marker"],
      apiKey: GOOGLE_MAPS_API_KEY,
    });

    const mapDiv = ref(null);
    let map = ref(null);
    let gAddress = ref(null);
    let drawingManager = ref(null);
    var geocoder = null;

    let display = ref(false);

    let selectedArea = ref("Área 1");
    var areas = {};
    let areaObj = ref({});
    let isSelected = ref({});
    let areaName = ref(null);

    for (let i = 1; i <= props.areas; i++) {
      let area_id = "Área " + i;
      areas[area_id] = null;
      isSelected.value[area_id] = false;
      areaObj.value[area_id] = {
        gobject: null,
        name: null,
        isSelected: false,
        geometry: null,
      };
    }

    onMounted(async () => {
      await loader.load();
      map.value = new google.maps.Map(mapDiv.value, {
        center: { lat: -13.197, lng: -51.644 },
        zoom: 4,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        labels: true,
        streetViewControl: false,
      });

      geocoder = new google.maps.Geocoder();

      const autocomplete = new google.maps.places.Autocomplete(gAddress.value, {
        componentRestrictions: { country: "br" },
      });
      autocomplete.setFields(["place_id", "geometry", "name"]);

      var polyOptions = {
        strokeWeight: 0,
        fillOpacity: 0.8,
        editable: true,
        draggable: false,
      };

      drawingManager.value = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: true,

        drawingControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_CENTER,
          drawingModes: ["circle", "polygon", "rectangle"],
        },
        polygonOptions: polyOptions,
      });

      drawingManager.value.setMap(map.value);

      google.maps.event.addListener(
        drawingManager.value,
        "overlaycomplete",
        function (e) {
          areaName.value = areaObj.value[selectedArea.value]["name"];
          display.value = true;
          drawingManager.value.setDrawingMode(null);
          var shape = e.overlay;
          if (areaObj.value[selectedArea.value]["gobject"] != null) {
            areaObj.value[selectedArea.value]["gobject"].setMap(null);
          }
          areaObj.value[selectedArea.value]["gobject"] = shape;

          var v = shape.getPath();
          let points = [];
          for (var i = 0; i < v.getLength(); i++) {
            var xy = v.getAt(i);
            points.push([xy.lat(), xy.lng()]);
          }
          areaObj.value[selectedArea.value]["geometry"] = points;
        }
      );
    });

    return {
      mapDiv,
      codeAddress,
      selectArea,
      deleteArea,
      selectedArea,
      areaName,
      gAddress,
      areaObj,
      drawingManager,
      display,
      submitModal,
      props,
      getGeometries,
    };
  },
};
</script>

<template>
  <div id="map-container">
    <div ref="mapDiv" style="width: 100%; height: 60vh" />
    <input
      id="gAddress"
      ref="gAddress"
      style="position: absolute"
      type="textbox"
      placeholder="Digite o endereço"
      @change="codeAddress()"
    />
    <ul class="area-button">
      <template v-for="area in props.areas" :key="area">
        <li>
          <button
            :class="{
              selected: areaObj['Área ' + area]['isSelected'],
              completed: areaObj['Área ' + area]['gobject'],
            }"
            @click="selectArea('Área ' + area)"
          >
            {{ areaObj["Área " + area].name || `Área ${area}` }}
          </button>
          <button
            class="delete"
            v-if="areaObj['Área ' + area]['gobject']"
            @click="deleteArea('Área ' + area)"
          >
            <img
              src="../../../public/images/trash-can.svg"
              style="width: 10px"
            />
          </button>
        </li>
      </template>
      <br />
    </ul>
  </div>

  <Dialog
    header="Confirmação de área"
    v-model:visible="display"
    style="margin-left: '50vw'"
    modal="True"
  >
    Selecione o nome para a {{ selectedArea }}:
    <input type="text" v-model="areaName" />
    <button @click="submitModal(selectedArea)">Confirmar</button>
  </Dialog>
</template>

<style>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li button {
  margin-top: 2px;
  margin-right: -4px;
  margin-bottom: -4px;
  height: 20px;
  cursor: pointer;
  font-family: = "Roboto,Arial,sans-serif";
}

li {
  margin-top: 2px;
  margin-right: -4px;
  margin-bottom: -4px;
}

li:first-child button {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
li:last-child button {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.selected {
  -webkit-box-shadow: inset 0px 0px 9px #333;
  -moz-box-shadow: inset 0px 0px 4px #333;
  box-shadow: inset 0px 0px 4px;
}
.completed {
  background: #42c957;
}

.delete {
  margin-left: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

li button:hover {
  background: #d3d3d3;
}

#map-container {
  width: 100%;
  height: 100%;
  position: relative;
}

#gAddress {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 3px !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  color: rgb(25, 25, 25);
  margin: 8px 0 22px;
  padding: 0 5px;
  position: fixed;
  float: right;
  top: 1%;
  left: 40%;
}

.area-button {
  padding: 0 5px;
  position: absolute;

  float: left;
  top: 50%;
  margin: 0px;
  outline: none;
  boxshadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

button {
  border-style: none;
  border-color: transparent;
}
</style>
