<template>
  <div class="" style="padding-bottom: 35px; padding-top: 20px">
    <h5 class="inputText">
      Qual o nome deseja dar para Região {{ index + 1 }}?
      <small v-if="valid.validations.regionName !== true" class="p-error">{{
        valid.validations.regionName
      }}</small>
    </h5>
    <InputText
      v-model="project.regionName"
      style="background: #ffffff; border: 2px solid #dadada; border-radius: 4px"
      type="text"
    />
    <span
      style="
        font-style: normal;
        font-weight: 900;
        font-size: 10px;
        color: rgba(0, 0, 0, 0.5);
        margin: 0;
        padding-left: 10px;
      "
    >
      Utilize um nome de fácil associação com o local do experimento. Este nome
      será usado no ambiente de navegação dos resultados.
    </span>
  </div>
  <div class="" style="padding-bottom: 35px">
    <h5 class="inputText">
      Qual cultivar irá utilizar na Região {{ index + 1 }}?
      <small v-if="valid.validations.regionGrowCrops !== true" class="p-error">{{
        valid.validations.regionGrowCrops
      }}</small>
    </h5>
    <InputText
      v-model="project.regionGrowCrops"
      style="background: #ffffff; border: 2px solid #dadada; border-radius: 4px"
      type="text"
    />
    <span
      style="
        font-style: normal;
        font-weight: 900;
        font-size: 10px;
        color: rgba(0, 0, 0, 0.5);
        margin: 0;
        padding-left: 10px;
      "
      >É importante que em todas as áreas desta região sejam utilizadas a mesma
      cultivar.</span
    >
  </div>
  <div style="padding-bottom: 20px">
    <h5 class="inputText" style="padding-bottom: 7px">
      Quando pretende iniciar o experimento na Região {{ index + 1 }}?
      <small v-if="valid.validations.data !== true" class="p-error">{{
        valid.validations.data
      }}</small>
    </h5>
    <Calendar
      style="background: #ffffff; height: 60px"
      v-model="project.data"
      autocomplete="off"
      inputId="icon"
      :showIcon="true"
    />
  </div>
  <h5 class="inputText" style="padding-bottom: 10px">
    Preencha as informações referentes as Áreas.
  </h5>
  <AreaSelection :areas="3" @geometries="(i) => (geometries = i)" />
</template>

<script>
import AreaSelection from "@/components/Map/AreaSelection.vue";

export default {
  components: {
    AreaSelection,
  },
  data() {
    return {
      d: {
        geometries: [],
      },

      project: {
        regionName: "",
        regionGrowCrops: "",
        data: "",
      },
    };
  },
  watch: {
    d: {
      handler: function (val) {
        this.$emit("change", { ...val });
      },
      deep: true,
    },
  },

  computed: {
    valid() {
      const validations = {
        regionName: !!this.project.regionName || "*",
        regionGrowCrops: !!this.project.regionGrowCrops || "*",
        data: !!this.project.data || "*",
      };
      return {
        isValid: Object.values(validations).every((v) => v === true),
        validations,
      };
    },
  },

  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style></style>
