<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <section class="">
          <h2
            style="
              font-style: normal;
              font-weight: 700;
              font-size: 29px;
              color: rgba(0, 0, 0, 0.7);
            "
          >
            Cadastrar um novo Projeto
          </h2>
          <div class="flex">
            <Chip label="4Bio" class="mr-2 mb-2 custom-chip chips" />
            <Chip label="Milho" class="mr-2 mb-2 custom-chip chips" />
          </div>
          <p
            style="
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #585857;
            "
          >
            Preencha o formulário para que possamos dar continuidade ao
            desenvolvimento do seu novo Projeto.
          </p>
        </section>
        <section class="register" v-if="!hasSeenCongrats">
          <div class="register-stepper">
            <div
              class="step"
              :class="{ 'step-active': step === 1, 'step-done': step > 1 }"
            >
              <span class="step-number">1</span>
            </div>
            <div
              class="step"
              :class="{ 'step-active': step === 2, 'step-done': step > 2 }"
            >
              <span class="step-number">2</span>
            </div>
            <div
              class="step"
              :class="{ 'step-active': step === 3, 'step-done': step > 3 }"
            >
              <span class="step-number">3</span>
            </div>
            <div
              class="step"
              :class="{ 'step-active': step === 4, 'step-done': step > 4 }"
            >
              <span class="step-number">4</span>
            </div>
          </div>

          <div class="register-stepper2">
            <span
              style="
                font-style: normal;
                font-weight: 800;
                font-size: 15px;
                line-height: 21px;
                color: #000000;
                margin-left: -10px;
                color: rgba(0, 0, 0, 0.66);
              "
              >Inicial</span
            >
            <span
              style="
                font-style: normal;
                font-weight: 800;
                font-size: 15px;
                line-height: 21px;
                color: #000000;
                margin-left: 5px;
                color: rgba(0, 0, 0, 0.66);
              "
              >Complementar</span
            >
            <span
              style="
                font-style: normal;
                font-weight: 800;
                font-size: 15px;
                line-height: 21px;
                color: #000000;
                color: rgba(0, 0, 0, 0.66);
              "
              >Geolocalização</span
            >
            <span
              style="
                font-style: normal;
                font-weight: 800;
                font-size: 15px;
                line-height: 21px;
                color: #000000;
                color: rgba(0, 0, 0, 0.66);
                margin-left: -20px;
              "
              >Conclusão</span
            >
          </div>

          <transition name="slide-fade">
            <section v-show="step === 1">
              <form
                class="form"
                method="post"
                action="#"
                @submit.prevent="next"
              >
                <div class="" style="padding-bottom: 35px">
                  <h5 class="inputText">
                    Qual o nome do Projeto?
                    <small
                      v-if="valid.validations.name !== true"
                      class="p-error"
                      >{{ valid.validations.name }}</small
                    >
                  </h5>
                  <InputText
                    style="
                      background: #ffffff;
                      border: 2px solid #dadada;
                      border-radius: 4px;
                    "
                    type="text"
                    v-model="project.name"
                  />
                  <span
                    style="
                      font-style: normal;
                      font-weight: 900;
                      font-size: 10px;
                      color: rgba(0, 0, 0, 0.5);
                      margin: 0;
                      padding-left: 10px;
                    "
                    >Utilize um nome de fácil associação com o local do
                    experimento. Este nome será usado no ambiente de navegação
                    dos resultados.</span
                  >
                </div>

                <div class="" style="padding-bottom: 35px">
                  <h5 class="inputText">
                    Qual o nome do produto?
                    <small
                      v-if="valid.validations.productName !== true"
                      class="p-error"
                      >{{ valid.validations.productName }}</small
                    >
                  </h5>
                  <InputText
                    style="
                      background: #ffffff;
                      border: 2px solid #dadada;
                      border-radius: 4px;
                    "
                    type="text"
                    v-model="project.productName"
                  />
                </div>

                <div class="" style="padding-bottom: 35px">
                  <h5 class="inputText">
                    Qual a dosagem do produto?
                    <small
                      v-if="valid.validations.productDosage !== true"
                      class="p-error"
                      >{{ valid.validations.productDosage }}</small
                    >
                  </h5>
                  <InputText
                    style="
                      background: #ffffff;
                      border: 2px solid #dadada;
                      border-radius: 4px;
                    "
                    type="text"
                    v-model="project.productDosage"
                  />
                </div>

                <!-- <div class="form-group">
                  <h5 class="inputText">Qual a cultura que será avaliada?</h5>
                </div>

                <div>
                  <SelectButton
                    v-model="value2"
                    :options="cultOptions"
                    optionLabel="value"
                    dataKey="value"
                    aria-labelledby="custom"
                  >
                    <template style="padding-right: 10px" #option="slotProps">
                      <div style="display: inline-grid">
                        <Image
                          :src="slotProps.option.icon"
                          alt="Image"
                          width="45"
                          style="padding-right: 10px"
                        />
                        <span>{{ slotProps.option.name }}</span>
                      </div>
                    </template>
                  </SelectButton>
                </div> -->
                <div
                  class="form-group cta-step"
                  style="justify-content: flex-end"
                >
                  <Button
                    style="
                      margin-top: 15px;
                      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                      border-radius: 10px;
                      background: linear-gradient(
                        90deg,
                        #90a956 0%,
                        #405b01 98.79%
                      );
                      padding: 15px;
                      padding-inline: 40px;
                    "
                    type="submit"
                    value="Next"
                    label="Próximo"
                    :disabled="!valid.isValid"
                  />
                </div>
              </form>
            </section>
          </transition>

          <!-- Regiões -->
          <transition name="slide-fade">
            <section v-show="step === 2">
              <div>
                <h5>Agora vamos registrar as suas áreas.</h5>
                <TabView v-model:activeIndex="regionTab" ref="tabview3">
                  <TabPanel
                    v-for="(region, i) in regions"
                    :key="`r-${i}`"
                    :header="`Região ${i + 1}`"
                  >
                    <RegionForm @change="(v) => (regions[i] = v)" :index="i" />
                  </TabPanel>
                  <TabPanel header="Nova Região"> </TabPanel>
                </TabView>
              </div>

              <div class="form-group cta-step">
                <Button
                  style="
                    margin-top: 15px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    background: linear-gradient(
                      90deg,
                      #90a956 0%,
                      #405b01 98.79%
                    );
                    padding: 15px;
                    padding-inline: 40px;
                  "
                  @click.prevent="prev()"
                  label="Voltar"
                />
                <Button
                  style="
                    margin-top: 15px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    background: linear-gradient(
                      90deg,
                      #90a956 0%,
                      #405b01 98.79%
                    );
                    padding: 15px;
                    padding-inline: 40px;
                  "
                  @click.prevent="next()"
                  label="Próximo"
                />
              </div>
            </section>
          </transition>
          <transition name="slide-fade">
            <section v-show="step === 3">
              <form
                class="form"
                method="post"
                action="#"
                @submit.prevent="next"
              >
                <div style="padding-bottom: 25px">
                  <h5 class="inputHeader">
                    Neste campo você pode adicionar pessoas que terão permissão
                    em realizar coleta para seus projetos. O coletor terá acesso
                    apenas ao aplicativo de coleta.
                  </h5>
                </div>
                <div class="" style="padding-bottom: 35px">
                  <h5 class="inputText">Nome</h5>
                  <InputText
                    style="
                      background: #ffffff;
                      border: 2px solid #dadada;
                      border-radius: 4px;
                    "
                    type="text"
                  />
                  <!-- <span
                    style="
                      font-style: normal;
                      font-weight: 900;
                      font-size: 10px;
                      color: rgba(0, 0, 0, 0.5);
                      margin: 0;
                      padding-left: 10px;
                    "
                    >Utilize um nome de fácil associação com o local do
                    experimento. Este nome será usado no ambiente de navegação
                    dos resultados.</span
                  > -->
                </div>

                <div class="" style="padding-bottom: 35px">
                  <h5 class="inputText">Email</h5>
                  <InputText
                    style="
                      background: #ffffff;
                      border: 2px solid #dadada;
                      border-radius: 4px;
                    "
                    type="text"
                  />
                </div>
                <div class="form-group cta-step">
                  <Button
                    style="
                      margin-top: 15px;
                      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                      border-radius: 10px;
                      background: linear-gradient(
                        90deg,
                        #90a956 0%,
                        #405b01 98.79%
                      );
                      padding: 15px;
                      padding-inline: 40px;
                    "
                    @click.prevent="prev()"
                    label="Voltar"
                  />
                  <Button
                    style="
                      margin-top: 15px;
                      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                      border-radius: 10px;
                      background: linear-gradient(
                        90deg,
                        #90a956 0%,
                        #405b01 98.79%
                      );
                      padding: 15px;
                      padding-inline: 40px;
                    "
                    type="submit"
                    value="Next"
                    label="Próximo"
                  />
                </div>
              </form>
            </section>
          </transition>
        </section>

        <transition name="slide-fade">
          <section v-show="step === 4">
            <form method="post" action="#" @submit.prevent="next">
              <div style="padding-bottom: 10px">
                <span
                  style="
                    font-style: normal;
                    font-weight: 900;
                    font-size: 17px;
                    line-height: 140.62%;
                    color: rgba(0, 0, 0, 0.66);
                  "
                  >Todos os dados de planejamento do projeto foram preenchidos.
                  Agora você pode clicar em “Enviar Projeto”, e nossa equipe de
                  microbiologistas vai avaliar o planejamento para verificar se
                  existe algum ajuste a ser feito.</span
                >
              </div>
              <br />
              <span
                style="
                  font-style: normal;
                  font-weight: 900;
                  font-size: 17px;
                  line-height: 140.62%;
                  color: rgba(0, 0, 0, 0.66);
                "
                >Confirme se todos os dados foram preenchidos corretamente,
                lembre-se que você possui a liberdade de navegar livremente por
                todas as etapas do novo cadastros.</span
              >
              <div class="form-group cta-step"></div>
            </form>
            <div class="flex">
              <div class="col-6 sm:col-6 md:col-6 lg:col-6 xl:col-10">
                <Button
                  style="
                    margin-top: 15px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    background: linear-gradient(
                      90deg,
                      #90a956 0%,
                      #405b01 98.79%
                    );
                    padding: 15px;
                    padding-inline: 40px;
                  "
                  @click.prevent="prev(), handleSubmit()"
                  label="Voltar"
                />
              </div>
              <div class="col-6 sm:col-6 md:col-6 lg:col-6 xl:col-2">
                <Button
                  style="
                    margin-top: 15px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    background: linear-gradient(
                      90deg,
                      #90a956 0%,
                      #405b01 98.79%
                    );
                    padding: 15px;
                    padding-inline: 40px;
                  "
                  label="Próximo"
                  type="submit"
                  @click="handleSubmit"
                  value="Next"
                />
              </div>
            </div>
          </section>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RegionForm from "@/components/newProject/RegionForm.vue";

export default {
  data() {
    return {
      regions: [null, null],
      regionTab: 0,
      project: {
        name: "",
        productName: "",
        productDosage: "",
        regionName: "",
        regionGrowCrops: "",
      },
      areas: null,
      value1: "Off",
      value2: null,
      center: { lat: -14.2400732, lng: -53.1805017 },
      options: ["4Bio", "4Corp"],
      steps: {},
      step: 1,
      hasSeenCongrats: false,
    };
  },
  watch: {
    regionTab(val) {
      if (this.regions.length === val) {
        this.regions.push(null);
      }
    },
  },
  methods: {
    handleSubmit() {
        console.log(this.project);
        axios
          .post("/api/submit", this.project)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    verifyFields() {
      if (!this.project.name)
        throw new Error("Por favor, preencha o nome do projeto.");

      if (!this.project.productName)
        throw new Error("Por favor, preencha o nome do produto.");

      if (!this.project.productDosage)
        throw new Error("Por favor, preencha a dosagem do produto.");
    },

    prev() {
      this.step--;
    },
    // next() {
    //   this.verifyFields();
    // },

    async next() {
      try {
        this.loading = true;
        this.verifyFields();
        this.step++;
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Erro ao entrar",
          detail: err.message || null,
          life: 3000,
        });
        this.loading = false;
      }
    },
    customerRegister: function () {
      this.hasSeenCongrats = true;
    },
    testemit() {
      console.log(this.areas);
    },
  },
  computed: {
    mapData() {
      if (!this.info) return null;
      return this.info.mapa.features[0].geometry.coordinates[0][0].map((c) => ({
        lat: c[1],
        lng: c[0],
      }));
    },
    valid() {
      const validations = {
        name: !!this.project.name || "*",
        productName: !!this.project.productName || "*",
        productDosage: !!this.project.productDosage || "*",
      };
      return {
        isValid: Object.values(validations).every((v) => v === true),
        validations,
      };
    },
  },
  components: { RegionForm },
};
</script>

<style lang="scss">
/* VARIABLES */
/* COLORS */
$brand-primary: #00c4b5;
$brand-secondary: #dedc00;
$brand-lemon: #fff219;
$brand-quaternary: #f282a5;
$brand-menthol: #14877d;
$brand-coral: rgb(250, 90, 85);
$brand-paprika: rgb(205, 0, 125);
$color-white: #fff;
$color-dark: #464646;
$color-gray: #cecece;
$color-lightgray: #ededed;
$color-jungle: #193805;

/* FONT */
$font-montserrat: "roboto";
$font-weight-bold: 700;

.pen-description {
  display: flex;
  flex-flow: column;
  align-items: center;
  font-family: $font-montserrat;

  h1 {
    text-align: center;
    margin-top: 2rem;
    color: $color-dark;
  }

  p {
    margin: 0;
    line-height: 1;
  }

  .pen-copyright {
    img {
      border-radius: 25px;
      padding: 5px;
      margin: 5px;
      transition: box-shadow 0.5s ease-in-out;
    }

    &:hover img {
      box-shadow: 0 10px 20px #0e2101;
    }
  }
}

.register {
  display: block;
  color: $color-dark;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 8px;
  &-icon {
    display: flex;
    background: black;
    border-radius: 2rem;
    width: 50px;
    height: 50px;
    padding: 1rem;
    margin: -50px auto 20px;

    &-item {
      width: 100%;
    }
  }

  .inputText {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140.62%;
    color: rgba(0, 0, 0, 0.66);
    margin: 0;
    margin-left: 7px;
  }

  .inputHeader {
    font-style: normal;
    font-weight: 750;
    font-size: 17px;
    line-height: 140.62%;
    color: rgba(0, 0, 0, 0.66);
    margin: 0;
    margin-left: 7px;
  }

  &-title {
    font-weight: 900;
    font-size: 25px;
    letter-spacing: 0.2rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    padding: 0 2rem;
    margin-top: 2rem;
  }

  &-stepper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-bottom: 0px;

    &::before {
      z-index: 0;
      content: "";
      display: block;
      position: absolute;
      height: 2px;
      top: calc(50% - 1px);
      background: $color-gray;
      width: calc(100% - 20px);
    }

    .step {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      border: 2px solid $color-gray;
      color: $color-gray;
      background-color: $color-white;
      border-radius: 50%;
      min-width: 25px;
      min-height: 25px;
      line-height: 20px;
      font-size: 16px;

      &-active {
        color: $brand-primary;
        background-color: $color-white;
        border-color: $brand-primary;
      }

      &-done {
        color: #a7e4b5;
        border-color: #a7e4b5;
      }

      &-number {
        font-family: $font-montserrat;
        font-weight: 800;
        line-height: 1;
        vertical-align: middle;
      }
    }
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: #dee2e6 #dee2e6 #ffffff #dee2e6;
    color: white;
    background: linear-gradient(90deg, #90a956 0%, #405b01 98.79%);
    padding: 20px;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid;
    border-width: 1px;
    border-color: #ffffff #ffffff #dee2e6 #ffffff;
    background: #ffffff;
    color: #6c757d;
    padding: 0.75rem 1rem;
    font-weight: 600;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    transition: box-shadow 0.15s;
    margin: 0 0 -1px 0;
    padding: 20px;
  }

  .p-calendar {
    display: flex;
    background: rgb(255, 255, 255);
    border: 2px solid rgb(218, 218, 218);
    border-radius: 4px;
  }

  .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    border-color: #888888;
    color: aliceblue;
    background: linear-gradient(90deg, #90a956 0%, #405b01 98.79%);
  }

  .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    border-color: #7a7a7a;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background: linear-gradient(90deg, #90a956 0%, #405b01 98.79%);
    color: white;
  }

  &-stepper2 {
    display: flex;
    justify-content: space-between;
    width: 103%;
    position: relative;
    margin: 0 auto 1.5em;
    margin-bottom: 30px;

    &::before {
      z-index: 0;
      display: block;
      position: absolute;
      height: 2px;
      top: calc(50% - 1px);
      background: $color-gray;
      width: calc(100% - 20px);
    }

    .step {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      border: 2px solid $color-gray;
      color: $color-gray;
      background-color: $color-white;
      border-radius: 50%;
      min-width: 25px;
      min-height: 25px;
      line-height: 20px;
      font-size: 16px;

      &-active {
        color: $brand-primary;
        background-color: $color-white;
        border-color: $brand-primary;
      }

      &-done {
        color: #a7e4b5;
        border-color: #a7e4b5;
      }

      &-number {
        font-family: $font-montserrat;
        font-weight: 800;
        line-height: 1;
        vertical-align: middle;
      }
    }
  }

  .form {
    &-group {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: baseline;

      label {
        text-align: left;
        font-size: 1.1rem;
        line-height: 1.1;
        padding-bottom: 0.5rem;
      }

      &.cta-step {
        color: $color-white;
        justify-content: space-between;

        .cta.prev {
          padding: 10px 30px;
        }
      }

      &.new-password {
        margin-top: 2rem;
      }
    }

    .cta-color,
    .cta-color input,
    .cta-color .link_text {
      font-family: $font-montserrat;
      font-size: 1.1rem;
      text-decoration: none;
      border: 1px solid black;
    }

    .cta-color .link_wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .arrow-prev {
        position: relative;
        display: inline-block;
        transform: translate(0);
        transition: transform 0.3s ease-in-out;

        &::before {
          content: "<";
          position: absolute;
          top: -17px;
          left: -25px;
        }
      }

      .arrow-next {
        position: relative;
        display: inline-block;
        transform: translate(0);
        transition: transform 0.3s ease-in-out;

        &::before {
          content: ">";
          position: absolute;
          top: -10px;
          left: -25px;
        }
      }

      &:hover .arrow-prev {
        transform: translate(-5px);
      }

      &:hover .arrow-next {
        transform: translate(5px);
      }
    }
  }
  // Override styles for input
  input[type="submit"],
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="date"] {
    -webkit-appearance: none;
    border: 0;
    border-radius: 5px;
    padding: 1.3rem 1rem;
    width: 100%;
    margin: 0.5rem;
  }

  input[type="submit"] {
    cursor: pointer;
    position: relative;
    padding-right: 36px;
    background: none;
    width: fit-content;

    &:hover,
    &:focus {
      box-shadow: unset;
      transform: none;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      border-radius: 50px;
      border: 1px solid $brand-primary;
      height: 25px;
      width: 25px;
      margin-top: -14px;
      pointer-events: none;
      transition: all 0.33s cubic-bezier(0.12, 0.75, 0.4, 1);
    }
  }

  &-btn input {
    color: $color-white;
    font-size: 1.2rem;
    font-family: $font-montserrat;
    font-weight: 800;
    line-height: 1;
    width: fit-content;
    background: linear-gradient(145deg, #1b3c05, #173205);

    &:hover {
      background: linear-gradient(145deg, #173205, #1b3c05);
    }
  }

  // Transition SLIDE FADE
  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    display: none;
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
}

.p-button {
  color: #ffffff;
  background: linear-gradient(90deg, #90a956 0%, #405b01 98.79%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 1px solid #90a956;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(248, 255, 205, 0.3);
  border-color: #90a956;
  color: #90a956;
}

.p-selectbutton .p-button {
  background: white;
  border: 1px solid #90a956;
  color: #90a956;
  transition: background-color 0.15s, border-color 0.15s;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.p-selectbutton .p-button.p-highlight:hover {
  background: rgba(248, 255, 205, 0.3);
  border-color: #90a956;
  color: #90a956;
}

.p-selectbutton .p-button.p-highlight {
  background: rgba(248, 255, 205, 0.3);
  border-color: #90a956;
  color: #90a956;
}

.congrats {
  background: $color-white;
  color: $brand-primary;
  padding: 4rem;
  text-align: center;

  &-subtitle {
    line-height: 1.3;

    strong {
      font-size: 2rem;
    }
  }
}

.chips {
  background: #90a956;
  color: white;
  font-weight: 700;
}
</style>
